<template>
  <div style="padding: 30px">
    <a-row :gutter="10">
      <a-col :span="24" style="margin-bottom: 10px">
        <!-- <a-card class="card-bg1">
          <a-col :span="4">
            <img
              src="@assets/total.png"
              alt=""
              class="card-img"
              @click="adddd"
            />
            <a-statistic title="设备总数" :value="totalDevice" />
          </a-col>
        </a-card> -->
      </a-col>
    </a-row>
    <a-row :gutter="10">
      <a-col :span="24"> </a-col>
    </a-row>
  </div>
</template>

<script>
import { getHomeDevice } from "@/api/api";
import store from "@/store";
export default {
  name: "Analysis",
  components: {},
  data() {
    return {
      store,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.data-title {
  font-weight: bold;
  font-size: 20px;
}
.query-from {
  margin-bottom: 20px;
}
.card-bg1 {
  width: 100%;
  height: 110px;
  /* background: url("~@assets/home.jpeg"); */
  background-size: 100%;
  background-color: rgb(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.card-bg1 .card-img {
  width: 40px;
  height: 40px;
}
.card-bg1 ::v-deep .ant-card-body {
  width: 100%;
  height: 110px;
  /* padding: 0 10%; */
}
.card-bg1 ::v-deep .ant-card-body .ant-col {
  padding: 0 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.ant-statistic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ant-statistic-content-suffix {
  /* transform: scale(0.8); */
  font-weight: 700;
}
.ant-statistic ::v-deep .ant-statistic-content-value {
  font-weight: 500;
  font-size: 30px !important;
}
.card-bg1
  ::v-deep
  .ant-card-body
  .ant-statistic
  .ant-statistic-content
  .ant-statistic-content-value {
  font-weight: 500 !important;
  color: #333;
  font-size: 30px !important;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 1000px;
}
.card-bg1 ::v-deep .ant-card-body {
  display: flex;
  justify-content: space-between;
}
</style>
